import React from 'react';
import About from '../assets/about.png';
import banner from '../assets/Rectangle 5.png';
import banner2 from '../assets/Frame 20.png';
import Project from '../assets/project1.png';
import Project2 from '../assets/Rectangle2.png';
import Project3 from '../assets/project3.png';
import Choose from '../assets/choose.png';
import Png1 from '../assets/Crane.png';
import Png2 from '../assets/fluteamwork.png';
import Png3 from '../assets/Group.png';
import Png4 from '../assets/Our.png';
import Png5 from '../assets/foundation.png';
import Png6 from '../assets/Engineering service.png';
import Png7 from '../assets/design.png';
import { Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import icn from '../assets/icon.png';
import icn1 from '../assets/icon1.png';
import icn3 from '../assets/icon3.png';
import icn4 from '../assets/icon4.png';
import icn5 from '../assets/icon5.png';

const HomeCotent = () => {
  return (
    <div className='flex flex-col w-screen'>
        <div className=' items-center justify-center flex flex-col'>
            <img className='w-full lg:h-[33rem] h-[20rem]' src={banner2} alt=''></img>
            <span className='flex flex-col items-center lg:-mt-[17rem] -mt-[12rem]
            '>
                <span className='lg:text-6xl text-2xl text-white'>Trucrete Solutions Limited</span>
                <span className='lg:text-xl text-base text-white'>We Create the Future Boldly, We Build Legacies.</span>
            </span>
            {/* <span className='text-xl px-3 py-2 rounded-xl bg-[#794B02] text-white mt-5'>Read More</span> */}
        </div>
        <div className='flex items-center justify-center lg:mt-[4rem] mt-[2rem]'>
            <span className='flex lg:px-8 px-2 py-2 gap-2 lg:py-4 lg:items-center lg:justify-center justify-between bg-[#362711] lg:gap-20 w-screen lg:w-fit'>
                <span className='flex flex-col lg:gap-2 gap-1'>
                    <img className='lg:w-16 w-6 lg:h-16 h-6' src={Png1} alt=''></img>
                    <span className='lg:text-2xl text-sm text-white'>Strategic Delivery</span>
                    <span className='lg:text-sm text-[0.5rem] text-white w-[7rem] lg:w-[15rem]'>Delivery of sustainable projects through collaboration and partnerships.</span>
                </span>
                <span className='flex flex-col lg:gap-2 gap-1'>
                    <img className='lg:w-16 w-6 lg:h-16 h-6' src={Png2} alt=''></img>
                    <span className='lg:text-2xl text-sm text-white'>Value Driven</span>
                    <span className='lg:text-sm text-[0.5rem] text-white w-[6rem] lg:w-[15rem]'>Commitment to value driven excellence in construction.</span>
                </span>
                <span className='flex flex-col lg:gap-2 gap-1'>
                    <img className='lg:w-16 w-6 lg:h-16 h-6' src={Png3} alt=''></img>
                    <span className='lg:text-2xl text-sm text-white'>Effective Teamwork</span>
                    <span className='lg:text-sm text-[0.5rem] text-white w-[6rem] lg:w-[15rem]'>Building with a highly motivated team that delivers.</span>
                </span>
            </span>
        </div>
        <div className='flex lg:flex-row flex-col gap-5 lg:gap-0 lg:px-24 px-4 pt-8 pb-8 lg:pt-24 lg:pb-10 justify-between'>
            <div className='flex flex-col lg:gap-3 gap-2'>
                <span className='text-2xl font-semibold text-[#DD8A03]'>About Us</span>
                <span className='flex flex-col text-base gap-2 lg:w-[40rem] lg:justify-start text-justify text-[#000000]/70'>
                    <p>Trucrete Solutions Limited is dynamic Construction Management firm with expertise in Infrastructural Development, Engineering Services, Procurement, Facility Management, and General Contracting.</p>
                    <p>With several groundbreaking engineering projects in our portfolio over the years, Trucrete is proud to have been involved in shaping Nigeria by elevating critical infrastructure to international standards, erecting buildings, bridges, flyovers and roads in key strategic locations in Nigeria. We have helped create a safer and more pleasant environment, all while encouraging state growth and development.</p>
                    <p>Our fleet of plant machinery and equipment gives us the capacity to successfully undertake multiple projects in various parts of Nigeria at any one time, while our network of tried and tested subcontractors and suppliers throughout the country and globally, enables us to procure the best resources for every project.</p>
                </span>
                <span className='flex gap-4'>
                    <Link to='/about-us'><span className='px-3 py-2 w-fit rounded-lg bg-[#362711] text-sm lg:text-base text-white hover:text-[#DD8A03]'>Read more</span></Link>
                    <Link to='/contact'><span className='px-3 py-2 w-fit rounded-lg bg-[#DD8A03] hover:[#362711] text-sm lg:text-base text-white'>Contact Us</span></Link>
                </span>
            </div>
            <span className=''><img className='w-[31rem]' src={About} alt=''></img></span>
        </div>
        <div className='bg-[#FEDFAF] lg:h-[50rem] h-[30rem] '>
            <img className='w-screen h-full' src={Png4} alt=''></img>
        </div>
        <div className='flex justify-center items-center -mt-[20rem] lg:-mt-[35rem] z-10'>
            <div className='flex flex-col items-center lg:w-[55rem] bg-[#362711] lg:py-10 py-8 gap-2 px-4 lg:px-0 lg:gap-4 '>
                <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Our Vision and Mission</span>
                <span className='flex flex-col gap-0 lg:gap-2 items-center'>
                    <span className='text-xl font-medium text-[#DD8A03]'>Mission</span>
                    <span className='text-sm lg:text-base text-white text-center lg:w-[40rem]'>To redefine the construction landscape by delivering unparalleled quality, innovation, and sustainability in every project we undertake. We are committed to exceeding client expectations, fostering collaborative partnerships, and enriching communities through our work.</span>
                </span>
                <span className='flex flex-col gap-0 lg:gap-2 items-center'>
                    <span className='text-xl font-medium text-[#DD8A03]'>Vision</span>
                    <span className='text-sm lg:text-base text-white text-center lg:w-[40rem]'>We envision a future where our projects inspire, our partnerships thrive, and our impact extends beyond construction sites to positively transform lives and environments. Through visionary leadership and a commitment to sustainable practices, we aspire to shape a brighter, more resilient world for generations to come.</span>
                </span>
                <span className='flex flex-col gap-0 lg:gap-2 items-center'>
                    <span className='text-xl font-medium text-[#DD8A03]'>Environmental Policy Statement</span>
                    <span className='text-sm lg:text-base text-white text-center lg:w-[40rem]'>Trucrete Solutions Limited is devoted to the provision of efficient, professional, comprehensive facility management, renovations, building and civil engineering, water engineering,procurement and general contracting services in a manner that prevents ill health and harm to the environment.</span>
                    <span className='text-sm lg:text-base text-white text-center lg:w-[40rem]'>We are passionate about the environment we operate and how our activities influence and impact the environment, as such, we are committed to executing our activities in an environmentally friendly manner to protect the environment and reduce the emission and release of pollutants into the environment.</span>
                    <span className='text-sm lg:text-base text-white text-center lg:w-[40rem]'>To achieve this, environmental objectives shall be set at all operational levels within the organization and regularly reviewed and monitored at planned intervals. We shall also identify and monitor all applicable compliance obligation as it relates to our activities and are committed to fulfilling them.</span>
                    <span className='text-sm lg:text-base text-white text-center lg:w-[40rem]'>Trucrete Solutions Limited does not condone activities that have the potential to cause harm to the environment and is invested in ensuring the continual improvement of the environmental management system and enhancement of its environmental performance.</span>
                </span>
            </div>
        </div>
        <div className='flex py-10 flex-col lg:gap-8 gap-5 items-center'>
            <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Our Services</span>
            <span className='flex lg:flex-row flex-col gap-5 lg:gap-8 px-4 lg:px-0'>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png5} alt=''></img>
                    <span className='text-2xl text-[#000000]/80 lg:w-[22rem]'>Infrastructure, Engineering and Construction Services</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[22rem]'>At Trucrete Solutions, we are managers of the construction process, committed to construction excellence and driven to perform beyond our customers expectations. Our focus on quality, safety, cost and on-time project delivery is uncompromising.</span>
                </span>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png6} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Electrical Engineering Services</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[22rem]'>We are into the supply of electrical equipment of different capacities and power ratings. Our manufactured products include Compact Systems, Transformers, Switch-Gears, High/Low Tension Galvanised Iron Poles, Disconnector/Isolator and other types of electrical equipment which are not mentioned here can also be produced upon customer’s request.</span>
                </span>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png7} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Design and Building</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[22rem]'>In an ever-changing construction industry, the design-build approach has increased substantially over the last decade. This method gives the customer the ability to work with a team comprising architects and a general contractor, helping to make the process of designing and constructing a project as efficient and as simple as possible.</span>
                </span>
            </span>
            <Link to='/services'><span className='flex items-end'><span className='lg:text-lg text-base px-3 py-2 rounded-xl hover:text-[#DD8A03] bg-[#794B02] text-white w-fit '>Learn More</span></span></Link>
        </div>
        <div className='flex flex-col'>
            <img className='w-screen h-[10rem] lg:h-[20rem]' src={banner} alt=''></img>
            <span className='lg:text-4xl text-xl flex text-center justify-center items-center text-white -mt-[6rem] lg:-mt-[10rem]'>Trusted Quality and Dedicated Service to Our Customers</span>
        </div>
        <div className='flex flex-col py-10 lg:gap-10 gap-5 lg:mt-[10rem] mt-5'>
            <span className='flex flex-col gap-2 justify-center items-center lg:px-24 px-4'>
                <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Project Highlights</span>
                <span className='lg:text-sm text-xs text-center'>Trucrete Solutions is committed to providing the highest quality of service to our clients. This commitment is shown in our previous and current projects and is the compass in procuring future projects. Our goal is to exceed our client’s needs and expectations all the while providing a quality project completion. As an indigenous firm, we have been building local knowledge and local relationships since existence.</span>
            </span>
            <span className='flex lg:flex-row flex-col justify-center lg:gap-12 gap-5 px-4 lg:px-0'>
                <span className='bg-[#F9F9F9] px-8 py-5 flex flex-col rounded-2xl gap-4 h-fit'>
                        <span className='text-xl font-semibold text-[#DD8A03]'>Aboru Project</span>
                        <span className='text-xs text-justify text-[#000000]/80 lg:w-60 flex flex-col gap-1'>
                            <span className='text-start'>Project Title: 14km Concrete lining of the Ajibola - Aboru river channel.</span>
                            <span>Client: Lagos State Government Project</span>
                            <span>Brief: Lagos being a coastal state has always been susceptible to flash floods during the rainy season. To this end, it became imperative to systematically connect all the flood control structures to the final outfall in Aiyetoro. The Akinola Aboru channelisation is meant to manage stormwater flow from Agege - okooba axis which serves as a feeder flow on one side and Bajomo - Ekoro - Akinola on the other side. These two meet at a confluence point where the flow is made to flow to Command - Ayobo into the Aiyetoro river. The channel has a 12m by 20m cross section upstream and 30m by 38m cross section downstream.</span>
                        </span>
                        <span className=''><img className='h-68 lg:w-60 w-full' src={Project3} alt=''></img></span>
                </span>
                <span className='bg-[#F9F9F9] px-8 py-5 flex flex-col rounded-2xl gap-4 h-fit'>
                    <span className='text-xl font-semibold text-[#DD8A03]'>Laspotech Project</span>
                    <span className='text-xs text-justify text-[#000000]/80 lg:w-60 flex flex-col gap-1'>
                        <span className='text-start'>Project Title: Laspotech 200 seater capacity theatre</span>
                        <span>Client: Lagos State Government Project</span>
                        <span>Brief: Construction of 200 seater capacity theatre with 4 offices. Project includes external parking space, Street lights powered by Solar, parking space with interlocking paving stones.</span>
                        <span className='text-start'>Project Title: Construction of dual carriageway Alpha Beach Road</span>
                    </span>
                    <span className=''><img className='h-68 lg:w-60 w-full' src={Project2} alt=''></img></span>
                </span>
                <span className='flex flex-col'>
                    <span className='bg-[#F9F9F9] px-8 py-5 flex flex-col rounded-2xl lg:gap-4 gap-2 h-fit'>
                    <span className='text-xl font-semibold text-[#DD8A03]'>Alpha Beach Project</span>
                    <span className='text-xs text-justify text-[#000000]/80 lg:w-60 flex flex-col gap-1'>
                        <span className='text-start'>Project Title: Construction of 2km dual carriageway Alpha Beach Road.</span>
                        <span>Client: Lagos State Government Project</span>
                        <span>Brief: In its effort to decongest the traffic on the Lekki-Epe expressway and to also serve as a tributary to the proposed South West coastal road, the Lagos State Government awarded the construction / expansion of this 2km road which was an earth road into a 16 metres wide dual carriageway with interlocking stone surface finish, street lights, service ducts and drainage on both sides.</span>
                    </span>
                    <span className=''><img className='h-68 lg:w-60 w-full' src={Project} alt=''></img></span>
                    </span>
                    <Link to='/projects'><span className='flex items-end justify-end'><span className='text-lg px-3 py-2 rounded-xl bg-[#794B02] hover:text-[#DD8A03] text-white mt-5 w-fit '>Learn More</span></span></Link>
                </span>
            </span>
        </div>
        <div className='flex lg:flex-row flex-col-reverse bg-[#362711] py-10 justify-center lg:gap-20 gap-10'>
            <span className='flex flex-col px-4 lg:px-0'>
                <span className='w-[25rem] h-[21rem] bg-[#FEDFAF] lg:flex hidden'></span>
                <span className='lg:-mt-72 lg:ml-12'><img className='lg:w-[25rem] w-full h-[20rem] lg:h-[21rem]' alt='' src={Choose}></img></span>
            </span>
            <span className='flex flex-col lg:gap-5 gap-2 px-4 lg:px-0'>
                <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Why Choose Trucrete?</span>
                <span className='flex flex-col gap-3 '>
                    <span className='flex flex-col gap-2 lg:w-[30rem]'>
                        <span className='text-base text-white'>At Trucrete Solutions, we prioritize a steadfast commitment to quality, innovation, and client satisfaction. Our team of experienced professionals brings unparalleled expertise to every project, ensuring excellence from start to finish. We embrace cutting-edge technologies and sustainable practices to deliver innovative solutions that exceed expectations. </span>
                        <span className='text-base text-white'>With a proven track record of success and a comprehensive range of services, including construction works, building construction, road construction, project management, and procurement/supplies, Trucrete Solutions is your trusted partner for bringing your vision to life.</span>
                    </span>
                </span>
            </span>
        </div>
        <div className='flex flex-col lg:px-24 px-4 py-10 gap-6'>
            <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03] flex justify-center items-center'>Our Customers</span>
            <Marquee>
                <img className='w-fit' src={icn} alt=''></img>
                <img className='w-fit' src={icn1} alt=''></img>
                <img className='w-fit' src={icn3} alt=''></img>
                <img className='w-fit' src={icn4} alt=''></img>
                <img className='w-fit' src={icn5} alt=''></img>

            </Marquee>
        </div>
    </div>
  )
}

export default HomeCotent;