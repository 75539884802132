import React from 'react';
import Rec5 from '../assets/Contact Us.png';
import Rec6 from '../assets/email-line.png';
// import Rec7 from '../assets/Phoneicon.png';

const ContactContent = () => {
  return (
    <div className='flex flex-col py-10 justify-center h-[33rem] lg:h-[30rem] items-center gap-10 relative'>
        <span className='absolute top-0'><img className='w-screen h-[33rem] lg:h-[30rem]' src={Rec5} alt=''></img></span>
        <span className='flex flex-col gap-4 items-center z-10'>
            <span className='lg:text-3xl tex-xl font-semibold text-[#DD8A03]'>Contact With Us</span>
            <span className='text-lg text-white text-center px-4'>Let's Create Progress Together For Great Business</span>
        </span>
        <span className='flex items-center z-10'>
            <span className='flex flex-col justify-center items-center lg:px-14 px-4 border-x border-white'>
                <img className='lg:w-20 w-16 lg:h-20 h-16' src={Rec6} alt=''></img>
                <span className='flex flex-col text-center'>
                    <span className='text-lg text-[#DD8A03]'>Email</span>
                    <span className='text-white lg:text-base font-medium'>info@trucreteng.com</span>
                </span>
            </span>
            {/* <span className='flex flex-col justify-center items-center lg:px-14 px-4'>
                <img className='lg:w-20 w-16 lg:h-20 h-16' src={Rec7} alt=''></img>
                <span className='flex flex-col text-center'>
                    <span className='text-lg text-[#DD8A03]'>Phone</span>
                    <span className='text-white lg:text-base font-medium'>+234 902 4769 507</span>
                </span>
            </span> */}
            {/* <span className='flex flex-col justify-center items-center px-14 -mb-8'>
                <img className='w-20 h-20' src={Rec6} alt=''></img>
                <span className='flex flex-col text-center'>
                    <span className='text-lg text-[#DD8A03]'>Address</span>
                    <span className='text-white text-sm w-[15rem]'>Headquarters: 223 A & B, Etim Inyang Crescent, Victoria Island, Lagos.</span>
                </span>
            </span> */}
            
        </span>
    </div>
  )
}

export default ContactContent;