import {React, useState} from 'react';
import Rec from '../assets/Rectangle2.png';
import Rec2 from '../assets/Subtract.png';
import Rec3 from '../assets/Rectangle3.png';
import Rec4 from '../assets/Rectangle4.png';
import Rec6 from '../assets/Rectangle5.png';
import { BsPlus, BsDot } from 'react-icons/bs'
import { BiMinus } from 'react-icons/bi';
import Rec5 from '../assets/About 2.png';

const ProjectsContent = () => {

    const [showMore, setShowMore] = useState({
        showMore1: false,
        showMore2: false,
        showMore3: false,
        showMore4: false,
        showMore5: false,
      });
    
      const toggleShowMore = (key) => {
        setShowMore((prevState) => ({
          ...Object.fromEntries(Object.entries(prevState).map(([k, v]) => (k === key ? [k, !v] : [k, false]))),
        }));
      };


  return (
    <div className='flex flex-col'>
        <div className='flex flex-col relative lg:h-[112rem] h-[188rem]'>
            <span className='absolute top-0'><img className='w-screen lg:h-[112rem] h-[188rem]' src={Rec5} alt=''></img></span>
            <div className='flex flex-col-reverse lg:flex-row lg:py-10 py-5 justify-center gap-5 px-4 lg:px-0 lg:gap-20 z-10'>
                <span className='flex items-center'>
                    <span className=''><img className='w-[25rem] lg:h-[23rem] h-[19rem]' src={Rec4} alt=''></img></span>
                    <span className='-ml-28'><img className='w-52 h-44' src={Rec2} alt=''></img></span>
                </span>
                <span className='flex flex-col gap-3 justify-center'>
                    <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Aboru Project</span>
                    <span className='flex flex-col gap-1 text-white lg:w-[33rem] lg:text-start text-justify'>
                        <span className='text-base'><span className='font-semibold text-lg'>Project Title:</span> 14km Concrete lining of the Ajibola - Aboru river channel.</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Client:</span> Lagos State Government Project</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Brief:</span> Lagos being a coastal state has always been susceptible to flash floods during the rainy season. To this end, it became imperative to systematically connect all the flood control structures to the final outfall in Aiyetoro. The Akinola Aboru channelisation is meant to manage stormwater flow from Agege - okooba axis which serves as a feeder flow on one side and Bajomo - Ekoro - Akinola on the other side. These two meet at a confluence point where the flow is made to flow to Command - Ayobo into the Aiyetoro river. The channel has a 12m by 20m cross section upstream and 30m by 38m cross section downstream.</span>
                    </span>
                </span>
            </div>
            <div className='flex flex-col lg:flex-row lg:py-10 py-5 justify-center gap-5 px-4 lg:px-0 lg:gap-20 z-10'>
                <span className='flex flex-col gap-3 justify-center'>
                    <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Alpha Beach Project</span>
                    <span className='flex flex-col gap-2 text-white lg:w-[33rem] lg:text-start text-justify '>
                        <span className='text-base'><span className='font-semibold text-lg'>Project Title:</span> Construction of 2km dual carriageway Alpha Beach Road</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Client:</span> Lagos State Government Project</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Brief:</span> In its effort to decongest the traffic on the Lekki-Epe expressway and to also serve as a tributary to the proposed South West coastal road, the Lagos State Government awarded the construction / expansion of this 2km road which was an earth road into a 16 metres wide dual carriageway with interlocking stone surface finish, street lights, service ducts and drainage on both sides.</span>
                    </span>
                </span>
                <span className='flex items-center'>
                    <span className=''><img className='w-[25rem] h-[23rem]' src={Rec3} alt=''></img></span>
                    <span className='-ml-28'><img className='w-52 h-44' src={Rec2} alt=''></img></span>
                </span>
            </div>
            <div className='flex flex-col-reverse lg:flex-row lg:py-10 py-5 justify-center gap-5 px-4 lg:px-0 lg:gap-20 z-10'>
                <span className='flex items-center'>
                    <span className=''><img className='w-[25rem] h-[23rem]' src={Rec} alt=''></img></span>
                    <span className='-ml-28'><img className='w-52 h-44' src={Rec2} alt=''></img></span>
                </span>
                <span className='flex flex-col gap-3 justify-center'>
                    <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Laspotech Project</span>
                    <span className='flex flex-col gap-2 text-white lg:w-[33rem] lg:text-start text-justify'>
                        <span className='text-base'><span className='font-semibold text-lg'>Project Title:</span> Laspotech 200 seater capacity theatre Project</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Brief:</span> Construction of 200 seater capacity theatre with 4 offices. Project includes external parking space, Street lights powered by Solar, parking space with interlocking paving stones.</span>
                    </span>
                </span>
            </div>
            <div className='flex flex-col lg:flex-row lg:py-10 py-5 justify-center gap-5 px-4 lg:px-0 lg:gap-20 z-10'>
                <span className='flex flex-col gap-3 justify-center'>
                    <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Reconstruction of Koton-Karfe</span>
                    <span className='flex flex-col gap-2 text-white lg:w-[33rem] lg:text-start text-justify '>
                        <span className='text-base'><span className='font-semibold text-lg'>Project Title:</span> Reconstruction of Koton -Karfe – Abaji Road (Abuja bound)</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Client:</span>Federal Ministry of Works Mabushi, Abuja</span>
                        <span className='text-base'><span className='font-semibold text-lg'>Brief:</span> Federal Government of Nigeria, through the Federal Ministry of Works with headquarters in Abuja, awarded construction of section III of this critical route that links Lokoja the capital of Kogi state to Abuja, the federal capital territory. Scope of works involve conversion of the existing asphalt section into a 200mm thick, reinforced concrete pavement and repair works of existing bridge and drainage infrastructure. The section is approximately 50 km starting from Koton – Karfe to Abaji.</span>
                    </span>
                </span>
                <span className='flex items-center'>
                    <span className=''><img className='w-[25rem] h-[23rem]' src={Rec6} alt=''></img></span>
                    <span className='-ml-28'><img className='w-52 h-44' src={Rec2} alt=''></img></span>
                </span>
            </div>
        </div>
        <div className=' bg-[#DD8A03] lg:px-24 px-0 py-3'>
            <span className='flex flex-col gap-3 px-4 py-4 rounded-sm border-b border-white'>
                <span className=' flex justify-between items-center opacity-90 cursor-pointer' onClick={() => toggleShowMore('showMore1')}>
                    <span className='lg:text-xl text-lg uppercase text-white'>ROAD CONSTRUCTION</span>
                    <span>{showMore.showMore1 ? <BiMinus className='w-8 h-8 text-white'/> : <BsPlus className='w-8 h-8 text-white'/>}</span>
                </span>
                {showMore.showMore1 && 
                    <span className='flex flex-col lg:pl-6 space-y-2 lg:py-4 py-2 opacity-70 bg-white'>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Proposed facilities (Drainage & Road) development of Palmvale estate at Ajah, behind Lekki garden – REDAN.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of 5km rigid pavement road at F-layout Minna Niger State – Flourmill.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of Drainage & Road with CBN Quaters Abuja – Subcontract role.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of Infrastructure & Road with CBN Quaters, Jos, Plateau – Subcontract role.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of 1.2km Road, Ijeh Road, Ikoyi Obalende LCDA – Lagos State Government.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>700 Meter Access Road to linking Lagos state waste transfer loading station to Adeniji Adele – Visionscape.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of Iyoha Community Road at Benin City, Edo State – Guinness Nigeria.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of asphalt road, concrete road, drainages and concrete culvert in different sites at ESUT University – N1.2 Billion.</span>
                        </span>
                    </span>
                }
            </span>
            <span className='flex flex-col gap-3 px-4 py-4 rounded-sm border-b border-white'>
                <span className=' flex justify-between items-center opacity-90 cursor-pointer' onClick={() => toggleShowMore('showMore2')}>
                    <span className='lg:text-xl text-lg uppercase text-white'>BUILDING & RENOVATION</span>
                    <span>{showMore.showMore2 ? <BiMinus className='w-8 h-8 text-white' /> : <BsPlus className='w-8 h-8 text-white'/>}</span>
                </span>
                {showMore.showMore2 && 
                    <span className='flex flex-col lg:pl-6 space-y-2 lg:py-4 py-2 opacity-70 bg-white'>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2  font-normal'>Construction and renovation of waste loading station at Tapa, Lagos State. (Visionscape Waste Management)</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Reconstruction of Caverton Helicopter Hanger Flooring (Lagos & Port Harcourt).</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Renovation of Caverton Helicopter.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Hanger & Main Office Building (Ikeja Airport).</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Design & Construction of Lekki Bedrock Estate (60 Units of Terrace Houses & 20 Blocks of Flats).</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Renovation of City Mall.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Design & Construction of Two (2) Numbers of Four (4) Bedroom Detached House, Magodo GRA, Lagos.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Design & Construction of Two (2) Numbers of Detached Houses at Lekki Phase 1, Lagos.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-3 font-normal'>Expansion of L.P.G Terminal Facility NIPCO Lagos, Nigeria.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>NNPC Petrol Station, Lagos – Ibadan Expressway.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Land Clearing & Project Monitoring for Construction of Trailer.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Park at Apapa – Oshodi Express Way, Isolo, Lagos (Subcontractor Role).</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Design & Construction of Block Flats at Osbourne, Ikoyi, Lagos (Supervision Role).</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Residential Renovation Works at Herbert Macaulay Crescent, GRA, Ikeja, Lagos.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Project Management & Monitoring of Mega High School Construction, Ogun State.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Renovation of a Transfer Loading Station at Oshodi, Lagos.</span>
                        </span>
                    </span>
                }
            </span>
            <span className='flex flex-col gap-3 px-4 py-4 rounded-sm border-b border-white'>
                <span className=' flex justify-between items-center opacity-90 cursor-pointer' onClick={() => toggleShowMore('showMore3')}>
                    <span className='lg:text-xl text-lg uppercase text-white pr-2'>ELECTRICAL ENGINEERING SERVICES WATER ENGINEERING</span>
                    <span>{showMore.showMore3 ? <BiMinus className='w-8 h-8 text-white'/> : <BsPlus className='w-8 h-8 text-white'/>}</span>
                </span>
                {showMore.showMore3 && 
                    <span className='flex flex-col lg:pl-6 space-y-2 lg:py-4 py-2 opacity-70 bg-white'>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-3 font-normal'>Rural Electrification, Street Light & Compact Systems for Street Light Projects (Electrification of Sayasaya (Albasu L.G.A), Saya-Saya (Kibiya L.G.A), Burum-Burum, Yankamaye & Yanmedi; HT./LT. Overhead Transformer Substations; Street Lights for Roundabouts and Compact System Substations for Street Lights) – Subcontractor Role from Biray Construction Nig. Ltd.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-3 font-normal'>Street Light Project at Ilorin – Ogbomoso Expressway.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Street Light Project at Ibeju-Lekki.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Supply and Installation of Transformers and Complete HT./LT. Underground Electrical Networking with Compact Substations at Kwankwasiyya City – Subcontract Role from Biray Construction Nig. Ltd.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Supply & installation of 11KV panel and 100KVA transformer for Glaxosmithkline (Agbara Green Project) – Subcontractor Role from Elektrint Nig. Ltd.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-3 font-normal'>Supply and Installation of 6300A 4-Pole Change-Over Panel (NBC) – Subcontractor Role from Elektrint Nig. Ltd.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Supply and Installation of 60 Street Light poles in Osun GRA.</span>
                        </span>
                    
                    </span>
                }
            </span>
            <span className='flex flex-col gap-3 px-4 py-4 rounded-sm border-b border-white'>
                <span className=' flex justify-between items-center opacity-90 cursor-pointer' onClick={() => toggleShowMore('showMore4')}>
                    <span className='lg:text-xl text-lg uppercase text-white'>WATER ENGINEERING WORKS</span>
                    <span>{showMore.showMore4 ? <BiMinus className='w-8 h-8 text-white'/> : <BsPlus className='w-8 h-8 text-white'/>}</span>
                </span>
                {showMore.showMore4 && 
                    <span className='flex flex-col lg:pl-6 space-y-2 lg:py-4 py-2 opacity-70 bg-white'>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of Borehole at CBN Quarters, Abuja.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of Borehole at Bedrock Estate, Lekki, Lagos, Nigeria.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Construction of Borehole at Tapa Wate Loading Station (Lagos State Government & Visionscape Waste Management).</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Servicing and maintaining of water treatment plant at First Mews Estate, Lekki, Lagos, Nigeria.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Design, Construction & Installation of Water Treatment Plant System (Inclusive of chemical dosing pumps, stirrer, aeration/sediments tank, filters.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Rehabilitation and refurbishment of Asejire Water Works Oyo State.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Installation of 75HP Submersible pumps, laying and connection of 35MM armored cable up to 250 meters distance at mini water works beside St. Saviour School, Bourdillon Road, Ikoyi, Lagos – Subcontract Role from Fordmarx Nig. Ltd.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Installation and servicing of 2 Number boreholes for Victoria Water Service Ltd, Victoria Garden City, Lagos – Subcontract Role from Fordmarx Nig. Ltd.</span>
                        </span>
                    
                    </span>
                }
            </span>
            <span className='flex flex-col gap-3 px-4 py-4 rounded-sm border-b border-white'>
                <span className=' flex justify-between items-center opacity-90 cursor-pointer' onClick={() => toggleShowMore('showMore5')}>
                    <span className='lg:text-xl text-lg uppercase text-white'>GENERAL PROCUREMENT/SUPPLIES</span>
                    <span>{showMore.showMore5 ? <BiMinus className='w-8 h-8 text-white'/> : <BsPlus className='w-8 h-8 text-white'/>}</span>
                </span>
                {showMore.showMore5 && 
                    <span className='flex flex-col lg:pl-6 space-y-2 lg:py-4 py-2 opacity-70 bg-white'>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-3 font-normal'>Supply of 20 Units of Hilux Pick Up Van to Chi Industries Nigeria Limited.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>4 x 25mm German Sheated Armoured Cable with 2 Units 500KVA step down transformer.</span>
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Supply and installation of 8500 NRS of plastic fiber stadium seat.</span>
                            
                        </span>
                        <span className='flex gap-2 items-center'>
                            <span className='text-xl font-bold'><BsDot /></span>
                            <span className='lg:text-lg text-base pr-2 font-normal'>Supply of furniture and fittings at Oyo State Governor’s Lodge Abuja.</span>
                        </span>
                    
                    </span>
                }
            </span>
        </div>
    </div>
  )
}

export default ProjectsContent