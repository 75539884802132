import React from 'react';
import Png5 from '../assets/foundation.png';
import Png6 from '../assets/Engineering service.png';
import Png7 from '../assets/design.png';
import Png8 from '../assets/manage_accounts.png';
import Png9 from '../assets/Frame 60.png';
import Png10 from '../assets/Group (1).png';
import Png1 from '../assets/Img.png';
import Png2 from '../assets/Img (1).png';
import Png3 from '../assets/Img (2).png';
import Png4 from '../assets/Img (3).png';
import banner from '../assets/Rectangle 5.png';

const ServicesContent = () => {
  return (
    <div className='flex lg:py-20 py-10 flex-col lg:gap-12 gap-6 items-center'>
        <div className='flex flex-col lg:gap-20 gap-10'>
            <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03] text-center'>Our Services</span>
            <span className='flex lg:gap-12 gap-6 lg:flex-row flex-col px-4 lg:px-0'>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png5} alt=''></img>
                    <span className='text-2xl text-[#000000]/80 lg:w-[25rem]'>Infrastructure, Engineering and Construction Services</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[25rem]'>At Trucrete Solutions, we are managers of the construction process, committed to construction excellence and driven to perform beyond our customers expectations. Our focus on quality, safety, cost and on-time project delivery is uncompromising.</span>
                </span>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png8} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Facility Management</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[25rem]'>Trucrete Solutions Limited provides facility management solutions for corporate and residential environment. Flexibility and innovation are the keys to our success, with in house capabilities to deliver multi and single service solutions to our clients.</span>
                </span>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png7} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Design and Building</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[25rem]'>In an ever-changing construction industry, the design-build approach has increased substantially over the last decade. This method gives the customer the ability to work with a team comprising architects and a general contractor, helping to make the process of designing and constructing a project as efficient and as simple as possible.</span>
                </span>
            </span>
            <span className='flex lg:gap-12 gap-6 lg:flex-row flex-col  px-4 lg:px-0'>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png10} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Procurement</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[25rem]'>At Trucrete Solutions Limited, our mission is to serve, support and collaborate with our client and each other so we can deliver innovative, timely and accurate solutions that create value and streamline processes in support of Trucrete Solutions Limited goals and initiatives.</span>
                </span>
                <span className='flex flex-col gap-2'>
                    <img className='w-20 h-20' src={Png6} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Electrical Engineering Services</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[25rem]'>We are into the supply of electrical equipment of different capacities and power ratings. Our manufactured products include Compact Systems, Transformers, Switch-Gears, High/Low Tension Galvanised Iron Poles, Disconnector/Isolator and other types of electrical equipment which are not mentioned here can also be produced upon customer’s request.</span>
                </span>
                <span className='flex flex-col gap-2'>
                <img className='w-20 h-20' src={Png9} alt=''></img>
                    <span className='text-2xl text-[#000000]/80'>Civil Engineering and Earthworks</span>
                    <span className='text-sm text-[#000000]/80 lg:w-[25rem]'>Trucrete is well equipped and trained to carryout major Civil Engineers Services - Road Construction, Road Rehabilitation, Drainage and Utility Installations -. We are partnered with a team of Italian and Irish Engineers for Technical expertise.</span>
                </span>
            </span>
        </div>
        <div className='flex flex-col'>
            <img className='w-screen h-[10rem] lg:h-[20rem]' src={banner} alt=''></img>
            <span className='lg:text-4xl text-xl flex text-center justify-center items-center text-white -mt-[6rem] lg:-mt-[10rem]'>Trusted Quality and Dedicated Service to Our Customers</span>
        </div>
        <div className='flex flex-col lg:gap-16 gap-8 lg:mt-[10rem] mt-[3rem]'>
            <span className='lg:text-3xl text-xl font-semibold text-center text-[#DD8A03]'>Our Abilities</span>
            <div className='flex lg:flex-row flex-col gap-7 lg:gap-10 px-4 items-center'>
                <div className='flex gap-2 lg:flex-row flex-row-reverse'>
                    <img src={Png1} className='w-40 h-28' alt=''></img>
                    <div className='flex flex-col gap-1 lg:gap-3 lg:w-[25rem]'>
                        <span className='lg:text-2xl text-xl text-[#000000]/80'>PROJECT MANAGEMENT</span>
                        <span className='text-sm text-[#000000]/80  w-[12rem] lg:w-fit'>We will assign a project manager, field superintendent, engineer, and other staff members as required to effectively manage the construction process.</span>
                    </div>
                </div>
                <div className='flex gap-2 '>
                    <img src={Png4} className='w-40 h-28' alt=''></img>
                    <div className='flex flex-col gap-1 lg:gap-3 lg:w-[25rem]'>
                        <span className='lg:text-2xl text-xl text-[#000000]/80'>REPORTING AND COST CONTROL</span>
                        <span className='text-sm text-[#000000]/80 '>During the construction process, we will provide up-to-date information with respect to changes that have occurred.</span>
                    </div>
                </div>
            </div>
            
            <div className='flex lg:flex-row flex-col gap-7 lg:gap-10 px-4 items-center'>
                <div className='flex gap-2 lg:flex-row flex-row-reverse'>
                    <img src={Png2} className='w-40 h-28' alt=''></img>
                    <div className='flex flex-col gap-1 lg:gap-3 lg:w-[25rem]'>
                        <span className='lg:text-2xl text-xl text-[#000000]/80'>COORDINATION PROCESS</span>
                        <span className='text-sm text-[#000000]/80 '>We will assign a project manager, field superintendent, engineer, and other staff members as required to effectively manage the construction process.</span>
                    </div>
                </div>
                <div className='flex gap-2'>
                    <img src={Png3} className='w-40 h-28' alt=''></img>
                    <div className='flex flex-col gap-1 lg:gap-3 lg:w-[25rem]'>
                        <span className='lg:text-2xl text-xl text-[#000000]/80'>COMMISSIONING AND PROJECT CLOSEOUT</span>
                        <span className='text-sm text-[#000000]/80'>One of the most critical stages of the construction process is the commissioning and closeout of the project.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesContent;