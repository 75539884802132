import React from 'react';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
// import { FaPhone } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='flex flex-col bg-[#362711]'>
        <div className='lg:px-24 px-4 lg:justify-between py-6 flex gap-3 lg:gap-0'>
            <span className='lg:mt-0 mt-1'><img className='lg:w-32 w-20' src={Logo} alt=''></img></span>
            <div className='flex gap-5'>
                <span className='flex flex-col lg:gap-4 gap-2 w-[4rem] lg:w-[20rem]'>
                    <span className='lg:text-2xl text-base font-semibold text-[#DD8A03]'>Company</span>
                    <span className='flex text-white text-sm lg:text-lg flex-col gap-1 lg:gap-2'>
                        <Link to='/'><span className='hover:text-[#DD8A03]'>Home</span></Link>
                        <Link to='/about-us'><span className='hover:text-[#DD8A03]'>About Us</span></Link>
                        <Link to='/services'><span className='hover:text-[#DD8A03]'>Our Services</span></Link>
                        <Link to='/projects'><span className='hover:text-[#DD8A03]'>Our Projects</span></Link>
                    </span>
                </span>
                <span className='flex flex-col lg:gap-4 gap-2  lg:w-[20rem]'>
                    <span className='lg:text-2xl text-base font-semibold text-[#DD8A03]'>Contact</span>
                    <span className='flex text-white text-sm lg:text-lg flex-col gap-1 lg:gap-2'>
                        <span className='flex lg:gap-2 gap-1 items-center'>
                            <MdEmail className='text-[#DD8A03] lg:text-xl text-base' />
                            <span>info@trucreteng.com</span>
                        </span>
                        {/* <span>Headquarters: 223 A & B, Etim Inyang Crescent, Victoria Island, Lagos.</span>
                        <span>Abuja Office: 4, Rhine Street, Maitama, Abuja</span> */}
                    </span>
                </span>
            </div>
            
        </div>
        <div className='flex justify-center py-3 border-t border-white text-[#DD8A03]'><span className='lg:text-sm text-xs'>Copyright © 2024 TrucreteNG | Powered by TrucreteNG</span></div>
        
    </div>
  )
}

export default Footer